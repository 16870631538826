import React from "react"
import { makeStyles, Typography, Card, CardMedia, CardContent } from "@material-ui/core"
import { logger } from '../config/pino';
import { TokenMetadata } from './TokenMetadata.interface'
import CircularProgress from '@material-ui/core/CircularProgress';
import ValidatorImage from '../images/NFTVal.jpg'
import { Skeleton } from "@material-ui/lab";


const useStyles = makeStyles((theme) => ({
  card: {
    width: 200,
    // height: 250,
    textAlign: 'center',
    margin: 0,
    borderRadius: '10px',
  },
  cardContent: {
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardText: {
    // display: 'flex',
    textAlign: 'left',
    fontSize: '0.8rem',
  },
  media: {
    margin: 0,
    objectFit: "contain"
  },
  loading: {
    height: '5rem',
    width: '100%',
  },
}));

// Takes an ERC721 Metadata object and renders a small card with it.
export function NFTMiniCard({metadata}) {
  // console.log(`Rendering mini card with data: ${JSON.stringify(metadata)}`)
  // Handle null render when given an empty object
  let isLoading;
  metadata && Object.keys(metadata).length === 0 && metadata.constructor === Object ? isLoading = true : isLoading = false

  const validatorIndex = !!metadata.EIPXXX && !!metadata.EIPXXX.validator_index ? metadata.EIPXXX.validator_index : '?'
  const navAttribute = !!metadata.attributes ? metadata.attributes.find((attribute) => {
    if (attribute.trait_type === "NAV") return true
  }) : {"trait_type": "NAV", "value": "TBC" }
  const nav = !!navAttribute ? navAttribute.value : 'TBC'
  const balanceAttribute = !!metadata.attributes ? metadata.attributes.find((attribute) => {
    if (attribute.trait_type === "Balance") return true
  }) : {"trait_type": "NAV", "value": "?" }
  const balance = !!balanceAttribute ? balanceAttribute.value : '?'
  const validatorImage = !!metadata.image ? metadata.image : ValidatorImage
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.card}>

      {isLoading ? <Skeleton variant="rect" className={classes.loading} /> :
        <CardMedia
          className={classes.media}
          component="img"
          // xyxy
          image={validatorImage}
          title="NFT Image"
        />
      }

      {!isLoading &&
        <CardContent className={classes.cardContent}>

          {/* <Avatar className={classes.avatar}>
            {!isError && (<NftIcon />)}
            {!!isError && (<ErrorOutlineIcon />)}
          </Avatar> */}

          <Typography className={classes.cardText} gutterBottom>
            <b>Validator:</b> #{validatorIndex}
          </Typography>
          <Typography className={classes.cardText} gutterBottom>
            <b>Balance:</b> {parseFloat(balance).toFixed(4)}
          </Typography>
          <Typography className={classes.cardText}>
            <b>NAV:</b> {parseFloat(nav).toFixed(4)}
          </Typography>
        </CardContent>
      }
    </Card>
  )
}
