import React from "react"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Typography, Grid, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { NFTMiniCard } from "../components/NFTMiniCard"
import { graphql, Link } from "gatsby"

// Page styles
const useStyles = makeStyles({
  paper: {
    padding: "4%",
  },
  maxWidthContainer: {
    margin: "0 auto",
    padding: "1rem",
    // using the padding for now to put the text near the middle
    // backgroundColor: '#aaffff'
  },
  maxWidthOpenSeaContainer: {
    alignItems: "center",
    background: "black",
    "border-bottom": "none",
    margin: "0 auto",
    marginBottom: "0",
    paddingBottom: "0",
    padding: "2%",
    width: "100%",
    "max-width": "100%",
  },
  openSeaImage: {
    display: "block",
    margin: "2% auto",
    paddingBottom: "2%",
    width: "50%",
    objectFit: "contain",
  },
  maxWidthScrollingContainer: {
    backgroundColor: "black",
    paddingTop: "5px",
    margin: 0,
    "max-width": "100%",
    width: "100%",
    "overflow-x": "scroll",
  },
  maxWidthContainerLogos: {
    // padding: '2%',
    margin: "0 auto 5px auto",
    width: "100%",
  },
  gridContainer: {
    width: "max-content",
    margin: "0 auto",
  },
  gridContainerLogos: {
    color: "black",
  },
  logoGridItem: {
    margin: "0 auto",
  },
  gridItem: {
    height: 220,
    width: 200,
    "text-align": "center",
    padding: "20px",
  },
  nftLink: {
    textDecoration: "none",
  },
  partnerLogoGridItem: {
    height: 100,
    width: 100,
    "text-align": "center",
    padding: "20px",
  },
  customFooter: {
    "text-align": "right",
    "margin-top": 80,
  },
  customFooterText: {
    fontSize: "0.7rem !important",
    "max-width": "100%",
  },
  // rename this whenever the style actually gets fleshed out
  customTextStyle: {
    color: "black",
    textAlign: "justify",
  },
})

export default function IndexPage({ data }) {
  const classes = useStyles()
  console.log(data)
  const currTime = new Date()
  const currHour = currTime.getHours()
  let ampm = "AM"
  if (currHour >= 12) {
    ampm = "PM"
  }
  return (
    <Layout>
      <SEO title="Kyne Software NFTs" />

      {/* Text area top of page */}
      <Container className={classes.maxWidthContainer} maxWidth="md">
        <Paper className={classes.paper} elevation={2}>
          <Typography
            gutterBottom
            className={classes.customTextStyle}
            style={{ fontWeight: "bold", fontSize: "1.2rem" }}
          >
            It's
            {
              // get hour AM/PM for users location
              currHour > 12
                ? ` ${(currHour % 12).toString()}${ampm}. `
                : ` ${currHour.toString()}${ampm}. `
            }
            Do you know where your validators are?
          </Typography>
          <Typography gutterBottom className={classes.customTextStyle}>
            Tokens representing staked ether create a liquid secondary market
            for Ethereum Validators, but they come with risks.
          </Typography>

          <ul>
            <li>
              <Typography gutterBottom className={classes.customTextStyle}>
                Who is custodying the withdrawal keys?
              </Typography>
            </li>
            <li>
              <Typography gutterBottom className={classes.customTextStyle}>
                Who is running the nodes?
              </Typography>
            </li>
            <li>
              <Typography gutterBottom className={classes.customTextStyle}>
                What data centre are they running in?
              </Typography>
            </li>
            <li>
              <Typography gutterBottom className={classes.customTextStyle}>
                What clients are they running?
              </Typography>
            </li>
          </ul>

          <Typography gutterBottom className={classes.customTextStyle}>
            Using ERC20s to represent a pool of staked ether exposes you to
            risks from every node operator in the pool. Paying operators
            proportional to the amount of ether they stake creates an incentive
            model to drive down operating costs and potentially safety margins
            to maximise revenue.
          </Typography>
          <Typography
            gutterBottom
            className={classes.customTextStyle}
            style={{ fontWeight: "bold", fontSize: "1.2rem" }}
          >
            So what are we supposed to do about this?
          </Typography>
          <Typography gutterBottom className={classes.customTextStyle}>
            Validator NFTs is an effort to tokenise individual Ethereum
            validators as standalone NFTs, rather than as an aggregated ERC20
            token. NFTs are a more solid base on-chain asset from which to build
            up staked ether derivatives.
          </Typography>
          <Typography gutterBottom className={classes.customTextStyle}>
            If you want to build a diverse portfolio of Validators hedging
            across clients and providers? With Validator NFTs you can.
          </Typography>
          <Typography gutterBottom className={classes.customTextStyle}>
            If you want to compose a basket of high quality validators into a
            tokenisable pool? With Validator NFTs you can.
          </Typography>
        </Paper>
      </Container>

      {/* 1: scrollable NFTMiniCards cards */}
      <Container className={classes.maxWidthScrollingContainer}>
        <Typography
          variant="subtitle1"
          align={"center"}
          gutterBottom
          style={{ color: "white", fontSize: "1rem" }}
        >
          Issued Validator NFTs
        </Typography>
        <Grid
          container
          className={classes.gridContainer}
          spacing={5}
          justify="center"
        >
          {data.nft.nfts.map(object => {
            return (
              <Grid item key={object.token_id}>
                <Link
                  to={`/nft/${object.address}/${object.token_id}/`}
                  className={classes.nftLink}
                >
                  <NFTMiniCard
                    // @ts-ignore
                    metadata={object}
                  ></NFTMiniCard>
                </Link>
              </Grid>
            )
          })}
        </Grid>
      </Container>

      {/* 2: More text content */}
      <Container className={classes.maxWidthContainer} maxWidth="md">
        <Paper className={classes.paper} elevation={2}>
          <Typography
            gutterBottom
            className={classes.customTextStyle}
            style={{ fontWeight: "bold", fontSize: "1.2rem" }}
          >
            Who controls these validators?
          </Typography>
          <Typography gutterBottom className={classes.customTextStyle}>
            Kyne Software works with expert custodians and professional staking
            firms to produce these NFTs.
          </Typography>
          <Typography gutterBottom className={classes.customTextStyle}>
            The owner of these NFTs can either sell them on the secondary market
            or redeem them for their earned rewards minus operating fees from
            our custodian. Our custodians are registered in the USA and comply
            with all KYC &amp; AML regulations applicable, as such, these assets
            are not suitable for persons from sanctioned countries or sanctioned
            business entities. For a full list of terms and conditions, please
            click here.
          </Typography>
        </Paper>
      </Container>

      {/* 3: Grid of partner logos */}
      <Container className={classes.maxWidthContainerLogos}>
        <Grid
          container
          spacing={4}
          className={classes.gridContainerLogos}
          justify="center"
        >
          <Grid item className={classes.logoGridItem}>
            <Paper className={classes.partnerLogoGridItem}>Partner Logo</Paper>
          </Grid>
          <Grid item className={classes.logoGridItem}>
            <Paper className={classes.partnerLogoGridItem}>Partner Logo</Paper>
          </Grid>
          <Grid item className={classes.logoGridItem}>
            <Paper className={classes.partnerLogoGridItem}>Partner Logo</Paper>
          </Grid>
          <Grid item className={classes.logoGridItem}>
            <Paper className={classes.partnerLogoGridItem}>Partner Logo</Paper>
          </Grid>
          <Grid item className={classes.logoGridItem}>
            <Paper className={classes.partnerLogoGridItem}>Partner Logo</Paper>
          </Grid>
          <Grid item className={classes.logoGridItem}>
            <Paper className={classes.partnerLogoGridItem}>Partner Logo</Paper>
          </Grid>
        </Grid>
      </Container>

      {/* 4: OpenSea section */}
      <Container className={classes.maxWidthOpenSeaContainer}>
        <Typography
          component="h1"
          variant="h5"
          align={"center"}
          gutterBottom
          style={{ color: "white", fontSize: "1rem" }}
        >
          Auctions for new Validator NFTs every week on
        </Typography>
        {/* wrap the image in a href to OpenSea */}
        <a href="https://opensea.io/" target="_blank" rel="noopener noreferrer">
          <img
            className={classes.openSeaImage}
            src={"/opensea-logo-flat-colored-white.png"}
            alt="Opensea Logo"
          />
        </a>
      </Container>

      {/* 5: footer (Leaving commented for now, will move to layout.js when I put real links in*/}
      {/* <Container className={classes.customFooter}>
          <CssBaseline />
          <Typography gutterBottom className={classes.customFooterText}>
            (C) Kyne Software 2021
              </Typography>
          <Typography gutterBottom className={classes.customFooterText}>
            Private and Confidential
              </Typography>
          <Typography gutterBottom className={classes.customFooterText}>
            Contact Us
              </Typography>
        </Container> */}
    </Layout>
  )
}

export const query = graphql`
  query NFTQuery {
    nft {
      nfts {
        external_url
        image
        name
        title
        address
        token_id
        EIPXXX {
          public_key
          document
          validator_index
          withdrawal_key
        }
        attributes {
          trait_type
          value
        }
      }
    }
  }
`
